<template>
  <div>
    <el-dialog
      title="离线数据导入及导出"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="600px"
    >
        <el-alert
          v-if="activeName == 'export'"
          title="导出离线数据"
          description="用于平台端数据同步至无网部署的上位机（数据范围为用户、产品、制程、3个月内订单及相关信息等）"
          type="info"
          show-icon
          :closable="false">
        </el-alert>
        <el-alert
          v-if="activeName == 'import'"
          title="导入离线数据"
          description="用于无网部署的上位机离线数据同步至平台端（使用csv格式文件，由上位机导出）"
          type="info"
          show-icon
          :closable="false">
        </el-alert>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="导出离线数据" name="export">
                <el-button
                  type="primary"
                  icon="el-icon-download"
                  size="small"
                  :loading="downloadLoading"
                  @click="handleExport()"
                  >开始导出</el-button
                >
              </el-tab-pane>
              <el-tab-pane label="导入离线数据" name="import">
                <el-upload
                  class="dataImport"
                  ref="dataImport"
                  :action="importApiUrl"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  :limit="1"
                  accept=".csv"
                  :data="importData"
                  :on-exceed="handleExceed"
                  :on-success="handleSuccess"
                  :on-error="handleError"
                  :on-change="handleChange"
                  :auto-upload="false"
                >
                  <el-button
                    slot="trigger"
                    icon="el-icon-folder-add"
                    size="small"
                    >选择文件</el-button
                  >
                  <div slot="tip" class="el-upload__tip">
                    请上传csv文件, 且不超过80MB
                  </div>
                  <el-popconfirm
                    icon="el-icon-info"
                    icon-color="red"
                    title="确定要导入文件吗？"
                    @confirm="submitDataImport()"
                  >
                    <el-button
                      style="margin-left: 10px"
                      type="primary"
                      icon="el-icon-upload2"
                      size="small"
                      slot="reference"
                    >
                      开始导入
                    </el-button>
                  </el-popconfirm>
                </el-upload>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose()">关 闭</el-button>
      </span>
    </el-dialog>

    <el-row :gutter="20" class="page-box task-list-page">
      <el-col>
        <Breadcrumb
          :home="false"
          :breadcrumbs="[
            {
              label: '车间管理',
              to: '/factory',
            },
          ]"
        />
      </el-col>
      <el-col>
        <el-card class="grid-card" shadow="never">
          <SchemaTable
            ref="schematableref"
            :schema="schema"
            :model="dataModel"
            :colConfig="{
              deployTypeName: { width: '120px' },
              onoffLineVal: { width: '120px' },
              createdTime: { width: '160px' },
              onoffLineTime: { width: '160px' },
              $actionBtns: { width: '200px' },
            }"
            :search="search"
            :filters="filters"
            :queryConf="queryConf"
            :hasReset="hasReset"
            queryLayout="appendBtnSearch"
          >
            <template #overrideActionBtns="{ row, onDelete }">
              <!-- 用 el-link制作按钮，添加 action-link 的 class 增加间距 -->
              <el-link
                type="primary"
                class="action-link"
                @click="goToDetail(row.id)"
              >
                详情
              </el-link>
              <el-link
                type="primary"
                class="action-link"
                icon="el-icon-files"
                @click="exportInfo(row)"
              >
                导入/导出
              </el-link>
              <el-link
                type="primary"
                class="action-link"
                @click="onDelete(row)"
              >
                删除
              </el-link>
            </template>
            <template #createdTime="{ row }">
              {{ dateFormat(row.created_at) }}
            </template>
            <template #onoffLineTime="{ row }">
              {{ dateFormat(row.updated_at) }}
            </template>
          </SchemaTable>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { SchemaTable, DataModel } from "enn-schema-table";
import schema from "./factory.schema.json";
import { uris, attachId } from "./../../api/uri";
import Breadcrumb from "enn-breadcrumb";
import axios from "axios";
import moment from "moment";
import { apiSync } from '../../api/api';

export default {
  components: {
    SchemaTable,
    Breadcrumb,
  },
  data() {
    return {
      search: '',
      fileList: [],
      timer: null,
      importApiUrl: '',
      importData: {},
      downloadLoading:false,
      filters: ["name", "deployType", "onoffLine"],
      schema,
      activeName: "export",
      hasReset: false,
      selectRow:{},
      dialogVisible: false,
      queryConf: { closePrefix: true },
      dataModel: new DataModel({
        getListApi: `${uris.factory}`,
        getListMap(item) {
          
          item.createdTime = +new Date(item.created_at);
          item.onoffLineTime = +new Date(item.onoffLineTime);
          // item.deployType = item.deployTypeName;
          item.onoffLineVal = item.onoffLine === 1 ? "在线" : "离线";
          return item;
        },
        getApi: `${uris.factory}${attachId}`,
        getMap(res) {
          return res;
        },
        deleteApi: `${uris.factory}${attachId}`,
        createApi: uris.factory,
        updateApi: `${uris.factory}${attachId}`,
        query: { pageNumber: 1, pageSize: 10 },
        axiosConfig: {
          timeout: 10000,
        },
        
      }),
    };
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    goToDetail(id) {
      this.$router.push({ path: `factory/${id}` });
    },
    exportInfo(row) {
      this.selectRow = row;
      this.importApiUrl = `${apiSync}/api/v1/factory/import?factoryId=${this.selectRow.id}&authorization=${localStorage.getItem("token")}`
      this.dialogVisible = true;
    },
    handleExport() {
      this.downloadLoading = true;
      axios
        .post(`${uris.factoryExport}`, {
          factoryId: this.selectRow.id,
        })
        .then((res) => {
          // exportProcess
          if (res.data.code !== 0) {
            this.downloadLoading = false;
            this.$message({
              type: "error",
              message: res.data.message,
            });
            return;
          }
          const { fileUrl, process, processId, ttlMsg} = res.data.data;
          if (fileUrl && process == 2) {
            this.$message({
              type: "success",
              duration: 10000,
              showClose: true,
              message: ttlMsg,
            });
            window.location.href = fileUrl;
            this.downloadLoading = false;
          } else {
            this.timer = setInterval(() => {
              this.factoryExportProcess(processId);
            }, 1000);
          }
        })
        .catch(err => {
          this.downloadLoading = false;
          this.$message({
            type: "error",
            message: err.toString(),
          });
        })
    },
    factoryExportProcess(processId){
       axios
        .get(`${uris.exportProcess}?processId=${processId}`, {
          
        }).then((res) => {
          if (res.data.code !== 0) {
            this.downloadLoading = false;
            this.$message({
              type: "error",
              message: res.data.message,
            });
            clearInterval(this.timer);
            return;
          }
          const { fileUrl, process, processId, error} = res.data.data;
          if (fileUrl && process == 2) {
            this.$message({
              type: "success",
              message: '离线数据导出成功',
            });
            clearInterval(this.timer);
            window.location.href = fileUrl;
            this.downloadLoading = false;
          } else if(error) {
            clearInterval(this.timer);
            this.$message({
              type: "success",
              message: error,
            });
            this.downloadLoading = false;
          }
        });
    },
    async submitDataImport() {
      if (this.importData.md5) {
        this.$refs.dataImport.submit();
      } else {
        this.$message.error("请选择csv文件！");
      }
    },

    // 导入文件相关方法（上传文件）
    handleRemove(file, fileList) {
      this.importData.md5 = "";
      console.log(file, fileList);
    },

    handlePreview(file) {
      console.log(file);
    },

    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    async handleSuccess(response, file, fileList) {
      if (!response.code) {
        this.importData.md5 = "";
        this.$message({
          message: "离线数据导入成功",
          type: "success",
        });
        await this.handleClose();
      } else {
        this.$message({
          message: response.message,
          type: "warning",
        });
        await this.handleClose();
      }
    },

    async handleChange(file) {
      this.importData.size = file.size;
      const that = this;
      const fileReader = new FileReader();
      const dataFile = file.raw;
      // const spark = new SparkMD5.ArrayBuffer();
      fileReader.readAsArrayBuffer(dataFile);
      //异步执行函数
      fileReader.onload = function (e) {
        // spark.append(e.target.result);
        // const md5 = spark.end();
        // console.log(md5);
        //此处this指向发生改变，需提前声明const _this = this
        // that.importData.md5 = md5; //此处是将文件的md5值放入dataImport中
        that.importData.md5 = "none"; // 复制为了后续检测
      };
    },

    async handleClose() {
      this.$refs.schematableref.onSearch();
      this.$refs.dataImport.clearFiles();
      this.dialogVisible = false;
    },

    async handleError(err, file, fileList) {
      if (err.status == 413) {
        this.$notify.error({
          title: "错误",
          message: "文件大小超出限制",
        });
      } else {
        this.$notify.error({
          title: "错误",
          message: "不支持此文件格式上传",
        });
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style scoped>
</style>